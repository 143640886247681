import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF1935', '#5E9EA0', '#2E8B57', '#FF1493', '#FFD700'];

const CustomTooltip = ({ active , payload , label  } :any) => {
    if (active) {
       return (
       <div
          className="custom-tooltip"
          style={{
             backgroundColor: "#ffff",
             padding: "5px",
             border: "1px solid #cccc"
          }}
       >
          <label>{`${payload[0].name} : ${payload[0].value}%`}</label>
       </div>
    );
 }
 return null;
};

function Charts(props:any) {
   const date = new Date(props.selectedYear, props.selectedMonth - 1, 1);
   const monthName = date.toLocaleString('default', { month: 'long' });
    const pieData =  props.inwardShipmentofMonth.map((d :any) => ({
        "name" : d.modeName,
        "value" : d.count
      }))
      console.log("pieData", pieData)
    return (        
        <>
        <p className="dash-head1" style={{textAlign:"center"}}>Outward Status &nbsp;&nbsp;{monthName}-{props.selectedYear} </p>
     <PieChart width={400} height={300}>
     <Pie
        data={pieData}
        color="#000000"
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={120}
        fill="#8884d8"
     >
        {pieData.map((entry :any, index :any) => (
           <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
           />
        ))}
     </Pie>
     <Tooltip content={<CustomTooltip />} />
     <Legend />
     </PieChart>
       </>
    )
  }
  
  export default Charts