import  { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import InwardContainerHeader from './InwardContainerHeader';
import MasterDataService from '../../services/masterData.service';
import '../inward/inwardlog.css';
import InwardLogService from '../../services/inwardlog.service';
import {useLocation, useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';

const InwardLog = () => {
  const navigate = useNavigate();
  const [shipmentmode, setshipmentmode] = useState([])
  const [modeName, setmodeName] = useState('');
  const [modeId, setmodeId] = useState(0);
  const [inwardDate, setinwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [inwardTime, setinwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
  const [serviceProvider, setserviceProvider] = useState('');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);
  const [showbranchlist,setshowbranchlist]=useState(false);
  const [frombranch, setfrombranch] = useState('');
  const [frombranchId, setfrombranchId] = useState(0);
  const [fromBranchList, setfromBranchList] = useState([]);
  const [noOfShipment, setnoOfShipment] = useState('');
  const [deliveryStaff, setdeliveryStaff] = useState('');
  const [logRemarks, setlogRemarks] = useState('');
  const [firstCall, setFirstCall] = useState(false);
  const [menifestNumber, setmenifestNumber] = useState('');
  const [manifestAwbNumbers, setManifestAwbNumbers] = useState([]); 
 
  const [searchQuery, setSearchQuery] = useState(''); 
  const [selectedRecordList, setselectedRecordList] = useState([])
  
  // Refs for input fields
  const modeRef = useRef<any>(null);
  const fromBranchRef = useRef<any>(null);
  const serviceProviderRef = useRef<any>(null);
  const noOfShipmentRef = useRef(null);
  const deliveryStaffRef = useRef(null);
  const logRemarksRef = useRef(null);
  const menifestNumberRef = useRef(null);
  const submitButtonRef = useRef(null);

  const handleKeyDown = (e :any, nextRef:any) => {  
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur(); 
      if (nextRef.current !== null) {
      nextRef.current.focus();
      } else {
        serviceProviderRef.current.focus();
      }
    }
  };
  const { state } = useLocation();   
  
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;    
    onSelectAll :any
  }

  const selectRow: selectRowType = {
    mode: "checkbox",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row :never, isSelect :any, rowIndex :any, e:any) => {         
        if(isSelect){ 
        const isAlreadySelected = selectedRecordList.includes(row['outwardDetailsId']);
        if (!isAlreadySelected) {
          setselectedRecordList([...selectedRecordList, row['outwardDetailsId']]);
        }

        }else {  
          const secondIndex = selectedRecordList.findIndex(
            (selectRow) => selectRow === row['outwardDetailsId']
          ); 
          if (secondIndex !== -1) { 
            const updatedList = [
              ...selectedRecordList.slice(0, secondIndex),
              ...selectedRecordList.slice(secondIndex + 1),
            ];           
            setselectedRecordList(updatedList);
          }
        }
      },
      onSelectAll: async (isSelect :any, rows :any, e :any) => {
       if(isSelect){
        var IdArray :[] = [];
        rows.map(async (rowDatas:never)=>{
            IdArray.push(rowDatas['outwardDetailsId']) ;
        })
        await setselectedRecordList(IdArray)
       }else {
       await setselectedRecordList([])
       }
      }, 
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  }; 
  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'manifestDetailId', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'Sr No',  align: 'center',sort: true },
    { dataField: 'AWB', text: 'AWB Number'},
    { dataField: 'employeeName', text: 'Consignee'},
    { dataField: 'consigneeName', text: 'Consignor'  }, 


  ];
  const filteredData = Array.isArray(manifestAwbNumbers)
    ?
    manifestAwbNumbers.filter((selectedRecords :any) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): value is string =>
          typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
      );
    })
    : [];
  const customStyles = {
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      padding: 5,
      zIndex: 1, 
      backgroundColor: '#f5f5f5', // Set the desired background color
      borderColor: '#ccc', // Optional: Set the border color
      boxShadow: 'none', // Optional: Remove the default shadow
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
    control: (prev: any) => ({
      ...prev,
      // none of react-select's styles are passed to <Control />
      padding: 0,
      width: "100%",
      backgroundColor: '#f5f5f5', // Set the desired background color
      paddingLeft:"0px",paddingRight:"0px"
  
    }),
    menu: (base: any, { isFocused, isSelected }: any) => ({
      ...base,
      zIndex: 100,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
  }
  useEffect(() => {
    modeRef.current.focus();
    if(state){
      getInwardLog()
    }
    getShipmentMode();
  }, [firstCall])
  const getInwardLog =async () => {
    if(state){            
      try {
        await InwardLogService.getLogById(state).then(
         async (data) => {
          if(data){
            setmodeId(data.modeName)
            if(data.modeName == 3){
              setshowbranchlist(true) 
              setfrombranch(data.frombranch)
              setfrombranchId(data.frombranchId)
              setmenifestNumber(data.menifestNumber)
              getmenifetsAWB(data.menifestNumber)
            }
            setserviceProviderId(data.serviceProvider)
            setinwardDate(data.inwardDate)
            setinwardTime(data.inwardTime)
            setnoOfShipment(data.noOfShipment) 
            setlogRemarks(data.logRemarks)
            setdeliveryStaff(data.deliveryStaff)
            setFirstCall(true)
          }   else {
            setFirstCall(true)
          }          
         }
        );
        
      } catch (error) {     
        console.error(error);
      }
    }
  }
  
  const getShipmentMode = () => {
    MasterDataService.GetshipmentMode().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName
          }))
          await setshipmentmode(options)
          if (state === null) {
            // if (data[0]) {
            //   await setmodeId(data[0]['id'])
            //   await setmodeName(data[0]['modeName'])
            // }
          } else {
            let modesNames = data.find((item: any) => {
              return item.id === modeId
            });
            if (modesNames !== undefined) {
              setmodeName(modesNames.modeName)
            }
          }
        }
        }
    );
  }
  useEffect(()=>{  
    setserviceProviderList([])  
    getServiceProvider();
  },[modeId])
  const getServiceProvider = async () => {
  
    if(modeId === 3 ){
       setserviceProviderId(0)
       setserviceProvider('')
       await MasterDataService.getBranch().then(
        async (data) => {
          if (data) {
            const options = data.map((d: any) => ({
              "value": d.id,
              "label": d.branchName
            }))
            await setfromBranchList(options)
            if (state === null) {
              if (data[0]) {
                await setfrombranchId(data[0]['id'])
                await setfrombranch(data[0]['branchName'])
              }
            } else {
              let branchLists = data.find((item: any) => {
                return item.id === frombranchId
              });
              if (branchLists !== undefined) {
                setfrombranch(branchLists.branchName)
              }
            }
          }
        }
      )
    } 
    MasterDataService.GetserviceProvider().then(
      async (data) => {
        if(data) {        
        const options = data
        .filter((d: any) => d.shipmentModeId === modeId)
        .map((d: any) => (
          {
          "value": d.id,
          "label": d.serviceProviderName
        }))
        // options.unshift({ value: 0, label: "None" });
         setserviceProviderList(options)
        if (state === null) {
          // if (data[0]) {
          //   await setserviceProviderId(data[0]['id'])
          //   await setserviceProvider(data[0]['serviceProviderName'])
          // }
        } else {
          let serviceProviderLists = data.find((item: any) => {
            return item.id === serviceProviderId
          });
          if (serviceProviderLists !== undefined) {
            setserviceProvider(serviceProviderLists.serviceProviderName)
          }
        }
      }
      }
    )
    
  }

  const handleModeChange = (selectedOption:any) => {
    console.log("selectedOption", selectedOption)
setmodeId(selectedOption.value);
setmodeName(selectedOption.label)
if(selectedOption.value == 3) {
  //setserviceProviderLabel("From Branch");
  setshowbranchlist(true); 
} else {
 // setserviceProviderLabel("Service Provider") 
 setshowbranchlist(false); 
}
}
  const handleServiceProvider = (selectedOption:any) => {
    setserviceProviderId(selectedOption.value);
    setserviceProvider(selectedOption.label)
  }
  const handlefromBranch = (selectedOption:any) => { 
    setfrombranchId(selectedOption.value);
    setfrombranch(selectedOption.label)
  }
  const inwardLogSubmit = () => { 
    let errorMessage = ""
    if((noOfShipment !== "") && (noOfShipment !== "0") && (deliveryStaff !== "")) {

    InwardLogService.inwardlogCreate(
      modeId,
      inwardDate,
      inwardTime,
      frombranch,
      frombranchId,
      serviceProviderId,
      noOfShipment,
      deliveryStaff,
      logRemarks,
      menifestNumber,
      selectedRecordList
    ).then(
      (data) => {
        if (data) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Inward Log Created Successfully",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              title: 'swal-fire-alert', // Add your custom CSS class here
            },
          }).then(function () {
            navigate('../inwardloglist');
          });
        }
      }
    ).catch((error) => {
      console.log(error)
    })
  } else {
    if ((noOfShipment === "") && (deliveryStaff === "")) {
      errorMessage = "Please Enter No of Pcs. Received And Delivery Staff Details";
    } else if (deliveryStaff === "") {
      errorMessage = "Please Enter Delivery Staff Name";
    } else if (noOfShipment === "") {
      errorMessage = "Please Enter No of Pcs. Received";
    }else {
      errorMessage = "Please Enter No of Pcs. Received";
    }
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    });
  }
  }
  const handleEdit = () => {
    let errorMessage = "";
    console.log(noOfShipment)
    if((noOfShipment !== "") && (noOfShipment !== "0") && (deliveryStaff !== "")) {
    InwardLogService.inwardlogUpdate( modeId,  inwardDate, inwardTime,frombranch,
      frombranchId, serviceProviderId,noOfShipment, deliveryStaff, logRemarks, menifestNumber, state
    ).then(
      (data) => {
        if (data) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Inward Log "+ data.batchNo+" Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              title: 'swal-fire-alert', // Add your custom CSS class here
            },
          }).then(function () {
            navigate('../inwardloglist');
          });
        }
      }
    ).catch((error) => {
      console.log(error)
    })
  } else {
    if ((noOfShipment === "") && (deliveryStaff === "")) {
      errorMessage = "Please Enter No of Pcs. Received And Delivery Staff Details";
    } else if (deliveryStaff === "") {
      errorMessage = "Please Enter Delivery Staff Name";
    } else if (noOfShipment === "") {
      errorMessage = "Please Enter No of Pcs. Received";
    }
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    });
  }
  }
  useEffect(()=>{ 
// setnoOfShipment(selectedRecordList.length.toString()) 
if (selectedRecordList.length === 0) {
  setnoOfShipment(''); // Set to blank when list is empty
} else {
  setnoOfShipment(selectedRecordList.length.toString()); // Set to string representation of length
}
  },[selectedRecordList])
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const handleDelete = () => {
    if (state !== null)
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          InwardLogService.deleteinwardLog(state).then(
            (data) => {              
              if(data){
                navigate("../inwardloglist");
              }
            }
          ).catch((error) => {
            console.log(error);
            console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Inward Log has been deleted",
          }).then(function () {
            navigate('../inwardloglist');
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Inward Log is safe :)",
            }).then(function () {
              window.location.reload()
            }
            )
        }
      })
  }

  const getmenifetsAWB = (menifestNumbers : any) => {
    InwardLogService.getmenifestDetails(menifestNumbers, frombranchId ).then((data)=>{
      setManifestAwbNumbers(data)
    })
  }

  return (
    <>
      <InwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}> Inward Log </p></div>
          <div className='row col-12'> 
        <div className='col-7' > 
      <div className='col-12' >      
        <div className='col-sm-12 inputs'>
          <label className='col-4'> Date & Time</label>
          <input type='date' className='col-5 zeroPadding' value={inwardDate} max={moment().format("YYYY-MM-DD")} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
          <input className='col-3 zeroPadding' value={inwardTime} type='time' name='inwardTime' onChange={e => setinwardTime(e.target.value)} />


        </div>
        <div className='col-sm-12 inputs'>
          <label className='col-4'> Mode</label>
          <div className='col-8 select' style={{paddingLeft:"0px",paddingRight:"0px"}}> 
                    <Select
                          ref={modeRef}
                          name="modeId"
                          onChange={handleModeChange}
                          options={shipmentmode}
                          styles={customStyles}
                          value={{ value: modeId, label: modeName }} 
                          onKeyDown={(event:any) => {
                            if (event.key === 'Enter' && modeRef.current) {
                              event.preventDefault();  
                              const selectedOption = modeRef.current.state.focusedOption ; 
                              if (selectedOption) {
                                handleModeChange(selectedOption);
                              } 
                              if(fromBranchRef.current === null){
                                handleKeyDown(event, serviceProviderRef); 
                              } else {
                                handleKeyDown(event, fromBranchRef); 
                              }
                            }
                        }}
                        />
            </div>
       </div>
     { showbranchlist && (<>
        <div className=' col-sm-12 inputs'>
          <label className='col-4'> From Branch </label>
          <div className='col-8 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          {/* <Select    
           ref={fromBranchRef}
            name="frombranch"
                      // onChange={handlefromBranch}
                      onChange={(selectedOption, event)=>{ handlefromBranch(selectedOption);  handleKeyDown(event, menifestNumberRef);}}
                      options={fromBranchList}
                      styles={customStyles}
                      value={{ value: frombranchId, label: frombranch }}
                      onKeyDown={(e) => handleKeyDown(e, menifestNumberRef)}
                    /> */}
                   
                   <Select    
    ref={fromBranchRef}
    name="frombranch"
    onChange={handlefromBranch}
    options={fromBranchList}
    styles={customStyles}
    value={{ value: frombranchId, label: frombranch }}
    onKeyDown={(event:any) => {
        if (event.key === 'Enter' && fromBranchRef.current) {
          event.preventDefault();  
          const selectedOption = fromBranchRef.current.state.focusedOption ; 
          if (selectedOption) {
              handlefromBranch(selectedOption);
          }
            // Access the value if fromBranchRef.current is not null
            // handlefromBranch(fromBranchRef.current.state.value);
        }
        handleKeyDown(event, menifestNumberRef);
        // Additional logic for key presses if needed
    }}
/>


                    </div>
       </div>  
  <div className=' col-sm-12 inputs'>
  <label className='col-4'> Menifest Number </label>
  <input type='text'  ref={menifestNumberRef} className='col-8 zeroPadding text-left' value={menifestNumber} name='menifestNumber' onChange={(e:any )=> {   setmenifestNumber(e.target.value); getmenifetsAWB(e.target.value)} }  onKeyDown={(e) => handleKeyDown(e, serviceProviderRef)} />
</div>
</>
)}

        <div className=' col-sm-12 inputs'>
          <label className='col-4'> Service Provider</label>
          <div className='col-8 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select  ref={serviceProviderRef}
                      name="serviceProviderId"
                      onChange={handleServiceProvider}
                      options={serviceProviderList}
                      styles={customStyles}
                      value={{ value: serviceProviderId, label: serviceProvider }}
                      // onKeyDown={(e) => handleKeyDown(e, noOfShipmentRef)}
                      onKeyDown={(event:any) => {
                        if (event.key === 'Enter' && serviceProviderRef.current) {
                          event.preventDefault();  
                          const selectedOption = serviceProviderRef.current.state.focusedOption ; 
                          if (selectedOption) {
                            handleServiceProvider(selectedOption);
                          } 
                        }
                        handleKeyDown(event, noOfShipmentRef);
                        // Additional logic for key presses if needed
                    }}
                    />
                    </div>
        </div>

        <div className=' col-sm-12 inputs'>
          <label className='col-4'> No of Shipment </label>
          <input type='text'  ref={noOfShipmentRef} className='col-8 zeroPadding text-left' value={noOfShipment} name='noOfShipment' onChange={(e:any )=> {  setnoOfShipment(e.target.value) }} onKeyDown={(e) => handleKeyDown(e, deliveryStaffRef)} onFocus={(event) => { event.target.select(); }} />
        </div>



        <div className=' col-sm-12 inputs'>
          <label className='col-4'> Delivery Staff </label>
          <input type='text'  ref={deliveryStaffRef} className='col-8 zeroPadding' value={deliveryStaff} name='deliveryStaff' onChange={e => setdeliveryStaff(e.target.value)}  onKeyDown={(e) => handleKeyDown(e, logRemarksRef)}/>
        </div>

        <div className=' col-sm-12 inputs'>
          <label className='col-4' >Log Remark </label>
          <input style={{ marginBottom: "3vh" }} type='text' ref={logRemarksRef} className='col-8 zeroPadding' value={logRemarks} name='logRemarks' onChange={e => setlogRemarks(e.target.value)} onKeyDown={(e) => handleKeyDown(e, submitButtonRef)} />
        </div>
      </div>
      </div>
      <div className='col-5' style={{overflow :'auto', height : '300px' }}>
      { showbranchlist && (<>
        <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign :'center'}}
              keyField='manifestDetailId'
              striped
              hover
              condensed 
              data={filteredData}
              columns={columns}
              pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            /> 
     </> ) }
      </div>
      </div>
      </div>
      <div>
        <div className='btn d-flex justify-content-center'>        
        {(state === null) ? <button className="btn2 col-2 col-sm-1" ref={submitButtonRef} type="button" onClick={inwardLogSubmit}><i className="fa fa-save"></i> Save</button> : <>
        <button className="btn2 col-2 col-sm-1"  type="button" onClick={handleEdit}  ><i className="fa fa-edit"></i> Update </button>
          <button className="btn2 col-2 col-sm-1"  type="button" onClick={handleDelete}> <i className="fa fa-trash"></i> Delete</button></> }
          <button className="btn2 col-2 col-sm-1"  type="button"> <i className="fa fa-close" ></i> Clear</button>
          <Link className="btn2 col-2 col-sm-1" to="../inwardloglist" type='button'>  <i className="fa fa-sign-out"> </i> Close </Link>
       
        </div>
      </div>
      </div>
    </>
  )
}

export default InwardLog