import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy =  localStorage.getItem('userId');
const updatedBy =  localStorage.getItem('userId');
const mailRoomId =  localStorage.getItem('mailRoomId');
const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
const config = {
  headers: {
    'Authorization': token,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  }
};

const inwarddetailCreate = async ( inwardDate :string,  inwardTime :string, batchNo:string, modeId :Number,  AWB:string,   serviceProviderId :Number, fromSource : Number, companyName :string, fromBranch : Number,  consignor :Number, consignorName:string, consignerCityName:string,  employeeId : Number, employeeName:string,inwardQuantity:Number,routeId :Number, department:Number,refNo :string, remark:string, mailContent: string,consignerpincode:string, countryName: string
   ) => {
    const bodyParamter = {
      inwardDate,
      inwardTime,
      batchNo ,
      AWB ,
      refNo,
      fromBranch,
      modeName : modeId,
      companyName,
      serviceProvider :serviceProviderId,
      inwardType : fromSource,
      consignerId : consignor,
      consignerName : consignorName ,
      consignerPhoneNumber:'',
      consignerCityName ,
      consignerZipCode : consignerpincode,
      consignerAreaName :'',
      consignerStateName : '',
      consignerCountryCode : countryName,
      inwardQuantity ,
      tCode :Date.now().toString(),
      employeeTableId:employeeId ,
      employeeName, 
      inwarddetailStatus :0,
      mailIntimation : 0,
      routeName: routeId,
      department,
      remark,
      mailContent,
      mailRoomId,
      branch,
      createdBy,
      baseUrl:API_URL,
    }
 return await axios
    .post(API_URL + "inwarddetail/register", bodyParamter , config)
    .then((response) => {
      return response.data;
    }).catch((error) => {
      console.log(error)
    });
}

const inwarddetailUpdate = async ( inwardDate :string,  inwardTime :string, batchNo:string, modeId :Number,  AWB:string,   serviceProviderId :Number, fromSource : Number, companyName :string,fromBranch : Number,  consignor :Number, consignorName:string, consignerCityName:string,  employeeId : Number, employeeName:string,inwardQuantity:Number,routeId :Number, department:Number,refNo :string, remark:string, mailContent: string, id: Number
  ) => {
   const bodyParamter = {
     inwardDate,
     inwardTime,
     batchNo,
     AWB,
     refNo,
     fromBranch,
     modeName : modeId,
     serviceProvider : serviceProviderId,
     inwardType : fromSource,
     consignerId : consignor,
     companyName,
     consignerName : consignorName ,
     consignerPhoneNumber:'',
     consignerCityName,
     consignerZipCode : '',
     consignerAreaName :'',
     consignerStateName : '',
     consignerCountryCode :'',
     inwardQuantity ,
     tCode :Date.now().toString(),
     employeeTableId:employeeId ,
     employeeName, 
     inwarddetailStatus :0,
     mailIntimation : 0,
     routeName: routeId,
     department,
     remark,
     mailContent,
     mailRoomId,
     branch,
     updatedBy
   }
return await axios
   .put(API_URL + "inwarddetail/"+ id, bodyParamter , config)
   .then((response) => {
     return response.data.message;
   }).catch((error) => {
     console.log(error)
   });
}

const getInwardDetailsList = async (inwardDate : any) => {
  const bodyParamter = {
    mailRoomId,      
    branch,
    inwardDate
    };
  return await axios.post(API_URL + "inwarddetail/", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const getdataforhdtracking = async (Fromdate:any,Todate:any,EmployeeId:Number,StatusId:Number,AWBNo:any,ModeId:any,DepartmentId:any,RouteId:any) => {
  const bodyParamter = {
    fromDate:Fromdate,
    toDate:Todate,
    employeeTableId:EmployeeId,
    mailRoomId, 
    inwarddetailStatus:StatusId,
    ModeId,
    DepartmentId, 
    RouteId,    
    branch,
    AWB:AWBNo
    };
  return await axios.post(API_URL + "inwarddetail/getdatafortracking", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const deleteInwardDetails = async (id:Number) => {
  return await axios.delete(API_URL + "inwarddetail/" + id, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const getInwardDetailsById = async (id:Number) => {
  return await axios.get(API_URL + "inwarddetail/" + id, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const getInwardDetailsPendigtoDeliveyList = async (inwardDate:any , pendingStatus :any) => {
  const bodyParamenters = {
    mailRoomId,
    branch,
    inwardDate,
    pendingStatus
  }
  // return await axios.post(API_URL + "inwarddetail/pending" , bodyParamenters, config).then(
  return await axios.post(API_URL + "inwarddetail/nomailintimation" , bodyParamenters, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const travellinghistory= async (AWB:any) => {
  const bodyParamter = {
    mailRoomId,      
    branch,
    AWB
    };
  return await axios.post(API_URL + "inwarddetail/travellinghistory/", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {   
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const getBranchAWBNumber = async () => {
  const bodyParamenters = {
    mailRoomId,
    branch
  }
  console.log("bodyParamenters", bodyParamenters)
  return await axios.post(API_URL + "inwarddetail/getbranchawb" , bodyParamenters, config).then(
    (response) => {
      console.log("response", response)
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error)) 
}

const importInwards = async (inwarddetail :any) => {
  const chunkSize = 100; // Set the desired chunk size for each iteration
  const totalChunks = Math.ceil(inwarddetail.length / chunkSize);

  for (let i = 0; i < totalChunks; i++) {
  const startIndex = i * chunkSize;
  const endIndex = Math.min((i + 1) * chunkSize, inwarddetail.length);
  const chunkData = inwarddetail.slice(startIndex, endIndex);

  // Create a request payload with the current chunk data
  const requestData = {
    inwarddetail:chunkData,
  };
  try {
      // Send the POST request to the server using Axios
       await axios.post(API_URL + "inwarddetail/import/", requestData, config )
      // Handle the response from the server if needed
  } catch (error) {
      // Handle errors if the request fails
      console.error(error);
  }
  }   
  return "Inward Details Imported"    
}

const postStatus = (uploadData : any) =>{
  const bodyParamter = {
    mailRoomId,      
    branch,
    uploadData
    };
return axios.post(API_URL + "inwarddetail/uploadstatus/", bodyParamter, config).then(
    (response) => {
        return response.data.message
    }
).catch(error => {return ""})  
}

const getmailfeedbackdata = async (tCode : any,empid:any) => {
  const bodyParamter = {
    tCode,
    empid
    };
  return await axios.post(API_URL + "statusupdate/inward", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
    .catch(error => console.log(error))
}

const getOutwardAWBDetails = async (outwardAWB :any) =>{
  const bodyParamter = {
    mailRoomId,
    outwardAWB
    };
  return await axios.post(API_URL + "outwarddetail/getdataforinward", bodyParamter, config).then(
    (response) => {
      if (response.data.statuscode === "200") {     
        return response.data.message
      }
    }
  )
}

const Inwarddetailsercice = {
  inwarddetailCreate,
  inwarddetailUpdate,
  getInwardDetailsList,
  getInwardDetailsById,
  deleteInwardDetails,
  getInwardDetailsPendigtoDeliveyList,
  travellinghistory,
  getBranchAWBNumber,
  importInwards,
  getdataforhdtracking,
  postStatus,
  getmailfeedbackdata,
  getOutwardAWBDetails
}

export default Inwarddetailsercice;