
function formatMonthName(monthName:any) {
  const [year, month] = monthName.split("-");
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthIndex = parseInt(month, 10) - 1;
  return `${monthNames[monthIndex]}-${year.slice(-2)}`;
}


function MonthWiseShipmentTable(props:any) {

  const totalCounts: number[] = Array(12).fill(0);
  // props.countData.forEach((d: any) => {
  //   d.countData.forEach((month: any, index: number) => {
  //     totalCounts[index] += month.count;
  //   });
  // });
  props.countData.forEach((data: any) => { 
    if (Array.isArray(data.countData)) {
      data.countData.forEach((monthData: any, index: number) => {
        const count = parseInt(monthData.count, 10); // Convert to number with base 10
        if (!isNaN(count)) { // Ensure it's a valid number
          totalCounts[index] += count; // Accumulate the count
        }
      });
    }
  });


  return (        
      <table className="table1 width ">
        <thead className="thead" style={{border : '1px solid #ccc'}}>
          <tr>
            <th> Monthly Inward Count</th>
          </tr>
        <tr style={{ textAlign: "center", border: "1px solid #ccc" }}>
            <th scope="col" style={{ border: '1px solid #ccc' }}>Mode</th> 
            {props.countData && Array.isArray(props.countData[0]?.countData) && props.countData[0].countData.map((monthDetails :any, index :number) => (
              <th key={index} style={{ border: '1px solid #ccc', fontWeight: 500 }}>{formatMonthName(monthDetails.month)}</th>
            ))}
          </tr>
        </thead>


        <tbody className="width scroll-table " style={{ border: "1px solid grey" }}>
{ 
Array.isArray(props.countData)
  ? props.countData.map( (d:any, index:any) =>{
  return <><tr key={index + 500} style={{textAlign: "center", border : '1px solid #ccc'}}>
<td style={{border : '1px solid #ccc', textAlign :"left"}}>
  {d.shipmentMode}
</td>
{
  d.countData.map((dates:any, ind:any) => {
   return <td key={ind + 7800} style={{border : '1px solid #ccc'}}>{dates.count} </td>
  })
}
  </tr> 
  

  </>
} ) : []}  
<tr style={{ textAlign: "center", border: '1px solid #ccc' }}>
          <td style={{ border: '1px solid #ccc', textAlign: "left" , fontWeight:'bold'}}>Total</td>
          {totalCounts.map((count, index) => (
            <td key={index + 10000} style={{ border: '1px solid #ccc', fontWeight:'bold' }}>{count}</td>
          ))}
        </tr>     
        </tbody>

      </table>
  )
}

export default MonthWiseShipmentTable