import  { useState, useEffect } from 'react';
import moment from 'moment';
import MasterDataService from '../../services/masterData.service';
import ReportContainerHeader from'../reports/ReportContainerHeader';
import ReportService from '../../services/report.service';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
const API_URL = process.env.REACT_APP_BASE_URL_API;

const OutwardReport = () => {
  // const navigate = useNavigate();
  const [shipmentmode, setshipmentmode] = useState([])
  const [modeName, setmodeName] = useState('');
  const [modeId, setmodeId] = useState<Number | 0>(0);
  const [fromdate, setfromDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [todate, settoDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [serviceProvider, setserviceProvider] = useState('');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [Employee, setEmployee] = useState('');
  const [EmployeeId, setEmployeeId] = useState(0);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [RouteList, setRouteList] = useState([]);
  const [Route, setRoute] = useState('');
  const [RouteId, setRouteId] = useState(0);
  const [Department, setDepartment] = useState('');
  const [DepartmentId, setDepartmentId] = useState(0);
  const [ReportTypeList,setReportTypeList] = useState<any | []>([]);
  const [ReportTypeId,setReportTypeId] = useState(0);
  const [ReportType,setReportType] = useState('');
  // const [excelpath,setexcelpath] = useState('');
  // const [bodyparams,setbodyparams] = useState();
  const mailRoomId = localStorage.getItem('mailRoomId');
  const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";

  

  
 const customStyles = {
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      padding: 5,
      zIndex: 1,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
    control: (prev: any) => ({
      ...prev,
      // none of react-select's styles are passed to <Control />
      padding: 0,
      width: "100%",
      paddingLeft:"0px",paddingRight:"0px"
  
    }),
    menu: (base: any, { isFocused, isSelected }: any) => ({
      ...base,
      zIndex: 100,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
  }
  useEffect(() => {
    getShipmentMode();
    getServiceProvider();
    getEmployee();
    getroute();
    getdepartment();
    getReportType();
  }, [])
 
  const getShipmentMode = () => {
    MasterDataService.GetshipmentMode().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName
          }))
          await setshipmentmode(options)
          setmodeId(0)
          setmodeName("All Mode")
          }
        }
    );
  }
  const getServiceProvider = () => {
    MasterDataService.GetserviceProvider().then(
      async (data) => {
        if(data) {            
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.serviceProviderName
        }))
        await setserviceProviderList(options)
        setserviceProviderId(0)
        setserviceProvider("All Service Provider")
       }
      }
    )
  }
  const getEmployee = () => {
    MasterDataService.getEmployeeList().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.employeeName
          }))
          await setEmployeeList(options)
          setEmployeeId(0)
          setEmployee("All Employee")
        }
        }
    );
  }
  const getroute = () => {
    MasterDataService.getRouteId().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.routeName
          }))
          await setRouteList(options)
          setRouteId(0)
          setRoute("All Route")
       }
        }
    ).catch(error => {
      console.error("Error fetching Routes data:", error);
      // Handle the error from the API call
  });
  }
  const getdepartment = () => {
    MasterDataService.getDepartment().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.name 
          }))
          await setDepartmentList(options)
          setDepartmentId(0)
          setDepartment("All Department")
        }
        }
    ).catch(error => {
      console.error("Error fetching department data:", error);
      // Handle the error from the API call
  });
  }
  const getReportType = () => {
           
          const options =  [
          {
              "value": 1,
              "label": "Detail Report"
          },
          {
            "value": 2,
            "label": "Delivery Report"
          },
          {
            "value": 3,
            "label": "Pending Report"
          },
          {
            "value": 4,
            "label": "Billing Report"
          }, 
          {
            "value": 5,
            "label": "RTO Report"
          },
        ]
            setReportTypeList(options)
            setReportTypeId(options[0]['value'])
            setReportType(options[0]['label'])
  }

const handleModeChange = (e:any) => {
setmodeId(e.value);
setmodeName(e.label)
  }
  const handleEmployee = (e:any) => {
    setEmployeeId(e.value);
    setEmployee(e.label)
  }
  const handleRoute = (e:any) => {
    setRouteId(e.value);
    setRoute(e.label)
  }
  const handleDepartment = (e:any) => {
    setDepartmentId(e.value);
    setDepartment(e.label)
  }
  const handleServiceProvider = (e:any) => {
    setserviceProviderId(e.value);
    setserviceProvider(e.label)
  }

  const handleReportType = (e:any) => {
    setReportTypeId(e.value);
    setReportType(e.label);
  }

const generateoutwardreport = async () => {
    let errorMessage = "";    
    if((ReportType !== "") && (fromdate !== "") && (todate !== "")) {
        interface MyObject {
        fromDate:string,
        toDate:string,
        modeId?:Number,
        serviceProviderId?:number,
        EmployeeId?:number,
        RouteId?:number,
        DepartmentId?:number
        mailRoomId:any,
        branch:number
       }
    
    let bodyParamter: MyObject = {
        fromDate:fromdate,
        toDate:todate,
        mailRoomId:mailRoomId,
        branch:branch
    }
    if(modeId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          modeId: modeId,
      }
      }
      if(serviceProviderId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          serviceProviderId: serviceProviderId,
      }
      }
      if(EmployeeId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          EmployeeId: EmployeeId,
      }
      }
      if(RouteId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          RouteId: RouteId,
      }
      }
      if(DepartmentId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          DepartmentId: DepartmentId,
      }
      }
    await ReportService.generateoutwardreport(
      bodyParamter,ReportTypeId
    ).then(
       (data) => {
          const link = document.createElement('a');
          link.href = API_URL+"uploads/"+data; // Replace with the actual file path
          link.download = 'report.xlsx';
          link.target = '_blank'; // Optional: Specify a different filename for the downloaded file
          link.click();
      }
    ).catch((error) => {
      console.log(error)
    })
  } else {
    if ((fromdate === "") && (todate === "")) {
      errorMessage = "Please Enter Date Range !!";
    } else if (ReportType === "") {
      errorMessage = "Please select report type !!";
    } 
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    });
  }
  }
  

  return (
    <>
    <ReportContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11' style={{ paddingLeft: "0px" }} >
        <div className="themeColor width" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
  <p style={{ marginLeft: "10px" }}>Outward Report</p>
  <div className='insameline'>
    <input type='date' className='col-6 zeroPadding' value={fromdate} max={moment().format("YYYY-MM-DD")} name='fromdate' onChange={e => setfromDate(e.target.value)} />
    <input type='date' className='col-6 zeroPadding' value={todate} max={moment().format("YYYY-MM-DD")} name='todate' onChange={e => settoDate(e.target.value)} />
  </div>
</div>

<div className='col-12' >   
      <div className=' col-sm-12 inputs'>
          <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="ReportType"
                      onChange={handleReportType}
                      options={ReportTypeList}
                      styles={customStyles}
                      value={{ value: ReportTypeId, label: ReportType }}
                    />
                    </div>
                      
       
      <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
      <Select
                      name="modeId"
                      onChange={handleModeChange}
                      options={shipmentmode}
                      styles={customStyles}
                      value={{ value: modeId, label: modeName }}
                    />
        </div>
        <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
        <Select     name="serviceProviderId"
                      onChange={handleServiceProvider}
                      options={serviceProviderList}
                      styles={customStyles}
                      value={{ value: serviceProviderId, label: serviceProvider }}
                    />
          </div>
          <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="EmployeeId"
                      onChange={handleEmployee}
                      options={EmployeeList}
                      styles={customStyles}
                      value={{ value: EmployeeId, label: Employee }}
                    />
          </div>
          <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="RouteId"
                      onChange={handleRoute}
                      options={RouteList}
                      styles={customStyles}
                      value={{ value: RouteId, label: Route }}
                    />
          </div>
          <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="DepartmentId"
                      onChange={handleDepartment}
                      options={DepartmentList}
                      styles={customStyles}
                      value={{ value: DepartmentId, label: Department }}
                    />
          </div>
        </div>
        </div>
<div>
  {/* Datatable Goes Here */}
  <div className="table-responsive tableContainer">
  {/* <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='id'
              striped
              hover
              condensed
              data={filteredDataDetails}
              columns={columnsDetails}
              // pagination={pagination}
              // selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            /> */}
  </div>
</div>

      <div>
        <div className='btn d-flex justify-content-center'>  
         
  <button className="btn2 col-2" type="button" onClick={generateoutwardreport}  rel="noreferrer" ><i className="fa fa-download"></i> Generate</button>  
   <Link className="btn2 col-2 col-sm-1" to=""> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
      </div>
      </div>
      </div>
      </div>
    </>
  )
}

export default OutwardReport