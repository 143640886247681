import { Link } from 'react-router-dom'

const ReportContainerHeader = () => {

    // const [classhideHeader, setclasshideHeader] = useState('');
    // const hideHeader = () => {
    //     setclasshideHeader("hideHeader");
    // }
    return (
        <div className='row col-12' >
            <ul className="nav nav-pills col-12 " id="pills-tab" role="tablist">
                <Link to="/inwardreport" className="navbar-item inwordbtn" >Inward</Link>
                <Link to="/outwardreport" className="navbar-item inwordbtn" >Outwards</Link>
                <Link to="/reconciliation" className="navbar-item inwordbtn" >Reconciliation</Link>
                <Link to="/misreport" className="navbar-item inwordbtn" >MIS Report</Link>
                <Link to="/adminmaterialoutreport" className="navbar-item inwordbtn" >Admin Material Out Report</Link>
            </ul>
        </div>
    )
}

export default ReportContainerHeader