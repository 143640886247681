import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Select from 'react-select';


const DashboardHeader = (props :any) => {
  
  return (
    <div>
      <div className='row col-12' >
     

        <ul className="nav nav-pills col-8 " id="pills-tab" role="tablist">
        <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="../dashboard" >
            Inward Dashboard
            </NavLink>
            <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="../outwarddashboard" >
            Outward Dashboard
            </NavLink>
            {/* <Link className="navbar-item inwordbtn" to="../dashboard" >Inward Dashboard </Link>
          <Link className="navbar-item inwordbtn" to="../outwarddashboard" >Outward Dashboard </Link>   */}
        </ul>
        <ul className="nav nav-pills col-4"  style={{verticalAlign: 'middle', marginTop:5}}>
       <li className='col-12 inline'>
       <label className='col-4'>Month/Year</label>
              <Select
               className='col-6'
                options={props.months}
                onChange={props.handleSelectChange}
                value={props.months.find((option:any) => option.value === `${props.selectedMonth}/${props.selectedYear}`)}
              />
       </li>
        </ul>
      </div>
    </div>
  )
}

export default DashboardHeader