import { useEffect, useState } from 'react'
import Inwarddetailsercice from '../../services/inwarddetail.service';
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import moment from 'moment';
import InwardContainerHeader from './InwardContainerHeader';
import MasterDataService from '../../services/masterData.service';
import Select from 'react-select';
import { read, utils } from 'xlsx';

function RTOInwardImport() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [selectedAWBNumber, setSelectedAWBNumber] = useState();
  const [inwardDateSearch, setinwardDateSearch] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allInwardDetails, setAllInwardDetails] = useState([]);
  
  const createdBy =  localStorage.getItem('userId');
  const updatedBy =  localStorage.getItem('userId');
  const mailRoomId =  localStorage.getItem('mailRoomId');

  const [Todate, setTodate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [ModeList, setModeList] = useState([]);
  const [Mode, setMode] = useState('Select Mode');
  const [ModeId, setModeId] = useState(0);

  const [frombranch, setfrombranch] = useState('Select from Branch');
  const [frombranchId, setfrombranchId] = useState(0);
  const [fromBranchList, setfromBranchList] = useState([]);
  const [courierType, setcourierType] = useState(0);
  const [pincodeLable, setpincodeLable] = useState('PinCode');

  const [serviceProvider, setserviceProvider] = useState('Select Service Provider');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);
  const [outwardAWB, setOutwardAWB] = useState<string[]>([]);

  const [inwardType, setinwardType] = useState(0)
  


 const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : ""; 
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
  }

  const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
      setSelectedAWBNumber(row.AWB);
    }
  } 
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'Sr. No.',  align: 'center'},  
     { dataField: 'batchNo', text: 'Batch Number' },
    { dataField: 'AWB', text: 'AWB Number' },
    { dataField: 'consignerName', text: 'Consignor' },
    { dataField: 'consignerCityName', text: 'From City' },
    { dataField: 'consignerZipCode', text: 'From Pincode' },
    { dataField: 'employeeId', text: 'Employee Id' },
    { dataField: 'employeeName', text: 'Employee' },
    { dataField: 'inwardQuantity', text: 'Pcs.' },
    { dataField: 'mailContent', text: 'Mail Content' },
    { dataField: 'remark', text: 'Remark'},
  ];
  //Table End  
  

  const navigate = useNavigate();
 

  const filteredData = Array.isArray(allInwardDetails)
    ? allInwardDetails.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];

      //------------excel import --------------
      const handleMode = (e: any) => {
        setModeId(e.value);
        setMode(e.label)
        if(e.value === 3){
          setinwardType(1)
        } else {
          setinwardType(0)
        }
      }
      const handleServiceProvider = (selectedOption: any) => {
        setserviceProviderId(selectedOption.value);
        setserviceProvider(selectedOption.label)
      }
    
    
      useEffect(() => {
        getMode();
        setinwardDateSearch((moment(new Date())).format('YYYY-MM-DD'))
      }, [])
    
      const handlefromBranch = (selectedOption: any) => {
        setfrombranchId(selectedOption.value);
        setfrombranch(selectedOption.label)
      }
      const getMode = () => {
        MasterDataService.GetshipmentMode().then(
          async (data) => {
            if (data) {
              const options = data.map((d: any) => ({
                "value": d.id,
                "label": d.modeName
              }))
              await setModeList(options)
            }
          }
        );
      }
    
    useEffect(()=>{  
      getServiceProvider();
    },[ModeId])
      const getServiceProvider = async () => {
        await MasterDataService.getBranch().then(
          async (data) => {
            if (data) {
              const options = data.map((d: any) => ({
                "value": d.id,
                "label": d.branchName
              }))
              await setfromBranchList(options)
            }
          }
        )
    
        MasterDataService.GetserviceProvider().then(
          async (data) => {
            if (data) {
              const options = data
                .filter((d: any) => d.shipmentModeId === ModeId)
                .map((d: any) => (
                  {
                    "value": d.id,
                    "label": d.serviceProviderName
                  }))
              options.unshift({ value: 0, label: "None" });
              // console.log("options ", data)
              setserviceProviderList(options)
            }
          }
        )
    
      }
    
     
    
  
      const handleImport = (event: any) => {
        if (!event.target.files) return;
        const files = event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event:any) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                  const newArr: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                  // var newArr :any = [...rows];
                  // console.log(newArr)
                  const awbObjects = newArr.map((data: any, index :any) => (
                   {
                    eid: index,
                    batchNo : data[Object.keys(data)[0]],
                    AWB : data[Object.keys(data)[2]], 
                    consignerName : '', 
                    consignerCityName : '',
                    consignerZipCode : '', 
                    employeeId : '',
                    employeeName : '',
                    inwardQuantity : '',
                    mailContent : '',
                    remark : data[Object.keys(data)[3]], 
                    refNo : data[Object.keys(data)[1]],
                    mailRoomId : mailRoomId,
                    branch: branch,
                    createdBy : createdBy, 
                 }
                 ));
           //For Get Outward Details Responce      
                const AWBNumber: string[] = awbObjects.map((obj:any) => obj.refNo);
                 setOutwardAWB(AWBNumber)

            //For Get Outward Details Responce

                    setAllInwardDetails(awbObjects);
                }
            }
            reader.readAsArrayBuffer(file);
    
        }
    }
  
    useEffect(() => {
      const getOutwardByAWB = async () => {
        if (outwardAWB.length > 1) {
          try {
            const data = await Inwarddetailsercice.getOutwardAWBDetails(outwardAWB);
            console.log(data);
            
            const updatedDetails :any = await Promise.all(
              allInwardDetails.map(async (inwardDetail: any) => {
                const matchedOutwardDetail = data.find((outwardDetail: any) => {
                  const outwardAWB = outwardDetail.AWB.toString().trim().toLowerCase();
                  const inwardRefNo = inwardDetail.refNo.toString().trim().toLowerCase();
                  return outwardAWB === inwardRefNo;
                });
    
                if (matchedOutwardDetail) {
                  return {
                    ...inwardDetail,
                    inwardQuantity: matchedOutwardDetail.numberOfPieces,
                    consignerName: matchedOutwardDetail.consigneeName,
                    consignerCityName: matchedOutwardDetail.consigneeCityName,
                    consignerZipCode: matchedOutwardDetail.consigneeZipCode,
                    employeeId: matchedOutwardDetail.employeeId,
                    employeeName: matchedOutwardDetail.employeeName,
                    mailContent: matchedOutwardDetail.mailContent,
                    // Update other details as needed
                  };
                }
                return inwardDetail;
              })
            );
    
            setAllInwardDetails(updatedDetails);
          } catch (error) {
            console.error("Error fetching outward AWB details:", error);
          }
        }
      };
    
      getOutwardByAWB();
    }, [outwardAWB]);
    
      //-------------excel import end here ---------
      const handleSubmit = () => {
        if(allInwardDetails.length > 0) {
          const updatedAwbObject = allInwardDetails.map((item :any) => ({
            ...item,
            inwardDate : Todate,
            inwardTime: moment().format('HH:mm:ss'),
            inwardType: inwardType,
            modeName: ModeId,
            courierType: courierType,
            serviceProvider: serviceProviderId,
            fromBranch: frombranchId
          }));

        Inwarddetailsercice.importInwards(updatedAwbObject).then(
          (data)=>{
            if(data){
              Swal.fire({
                title: "Inward Details Import in Progress",
                icon: 'success',
                text: "Inward Details has been Imported Please Wait....",
            }).then(function() {
            navigate("../inworddetailslist")
            })  
            }
          }
        )
        }
      }
      
  return (
    <>
      <InwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Inward Details </p></div>
            <div className='col-12 inline'>
              <div className='col-3'>
                <label> Inward Date</label>
                <input className='margininutleft' type='Date' value={Todate} max={moment().format("YYYY-MM-DD")} name="logDateSearch" onChange={(e) => { setTodate(e.target.value) }} />
              </div>
              <div className='col-4 sec4-radio'>
                <div className='sec4-radio1 direct1'>
                  Destination : &nbsp; <label className='inline' ><input
                    name="connected"
                    id="home"
                    type="radio"
                    value="first"
                    className="sec1-radio radio-btn"
                    onChange={() => { setcourierType(0); setpincodeLable('PinCode') }}
                    checked={courierType === 0 && true}
                  />Domestic </label>
                </div>
                <div className='sec4-radio1 direct1'>
                  <label className='inline' ><input
                    name="connected"
                    id="home"
                    type="radio"
                    value="first"
                    onChange={() => { setcourierType(1); setpincodeLable('ZipCode') }}
                    checked={courierType === 1 && true}
                    className=" sec1-radio radio-btn "
                  />International</label>
                </div>
              </div>
              <div className='col-5'>
                <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
              </div>
            </div>
            <div className='col-12 inline' style={{ padding: '0px', paddingBottom: "10px", textAlign: 'left' }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%",  paddingTop: '1%' }} >
                  <div className='col-3' >
                    <Select name="ModeId"
                      onChange={handleMode}
                      options={ModeList}
                      value={{ value: ModeId, label: Mode }}
                    />

                  </div>

                  {ModeId === 3 && (<div className='col-3' > <Select name="fromb"
                    onChange={handlefromBranch}
                    options={fromBranchList}
                    value={{ value: frombranchId, label: frombranch }}
                  />
                  </div>)}

                  <div className='col-3' >
                    <Select name="serviceProvider"
                      onChange={handleServiceProvider}
                      options={serviceProviderList}
                      value={{ value: serviceProviderId, label: serviceProvider }}
                    />
                  </div>
                 
                 
                </div>
              </div> 
              <div className="table-responsive tableContainer" >
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='eid'
              striped
              hover
              condensed
              // data={allServiceProvider}
              data={filteredData}
              columns={columns}
              // pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
</div>
          </div>
          <div className='row col-12'>
          <div className='row col-4 d-flex justify-content-center'>
          <Link to="../uploads/excelformat/RTOinward.xlsx"  className="btn2 col-6"  target='_blank' > <i className='fa fa-download'></i> Download Template</Link>
       </div>
          <div className='row col-8 d-flex justify-content-end'>
           <Link to="" className="btn2 col-2" onClick={handleSubmit} > <i className="fa fa-plus" aria-hidden="true"></i>Submit</Link>
            <button className="btn2 col-2" > <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RTOInwardImport