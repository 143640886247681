import  { useEffect, useRef, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataService from '../../services/masterData.service'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import './master1.css'

const Shipmentmode = () => {

  const [modeName, setmodeName] = useState("");
  const [shortName, setshortName] = useState("");
  const { state } = useLocation();

  const modeNameRef = useRef<any>(null);
  const shortNameRef = useRef<any>(null);
  const saveButtonref = useRef<any>(null);

  const navigate = useNavigate();

  const handleKeyDown = (e :any, nextRef:any) => {   
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
      if (nextRef.current !== null) {
      nextRef.current.focus();
      }
    }
  };

  const HandleSubmit = () => {
    if(modeName !="" && shortName !=""){
    MasterDataService.PostshipmentMode(modeName, shortName).then(
      (data) => {
        if (data) {
          let message = "Shipment Mode Added Successfully"
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500,
    }).then(()=>{
      navigate('../shipmentmodelist');
    });
          
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  } else {
  let message = "Please Enter Shipment Mode And/Or Short Name"
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
  // edit the selected data
  const HandleEdit = () => {
    MasterDataService.PutshipmentMode(modeName, shortName, state).then(
      (data) => {
        console.log(data.statuscode);
        if (data.statuscode === 200) {
          navigate('./shipmentmodelist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  //  get shipment by Id
  const GetshipmentMode = async () => {
    try {

      const data = await MasterDataService.GetshipmentModeId(state);
      if (data) {
        setmodeName(data.modeName);
        setshortName(data.shortName);
      } else {
        setmodeName('');
        setshortName('');
        // Handle the case when data.message is undefined or null
      }
    } catch (error) {
      // Handle errors from the API call
      console.error(error);
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          MasterDataService.DeleteshipmentMode(state).then(
            (data) => {
              if(data){
              navigate('/shipmentmodelist');
            }
            }
          ).catch((error) => {
            console.log(error);
            console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Shipment Mode has been deleted",
          }).then(function () {
            navigate('/Shipmentmodelist');
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Shipment Mode is safe :)",
            }).then(function () {
              navigate('/Shipmentmodelist');
            }
            )
        }
      })

    GetshipmentMode();
  }

  const HandleCancel = () => {
    setmodeName("");
    setshortName("");
  }
  useEffect(() => {
    modeNameRef.current.focus();
    if (state !== null) {
      GetshipmentMode();
    }
  }, [])



  return (
    <>
      <MasterHeader />

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11'style={{paddingLeft:"0px"}} >
          <div className="themeColor width" >
            <p style={{ marginLeft: "10px" }}>Shipment Mode </p></div>

          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b>Shipment Mode</b></label>
            <input ref={modeNameRef} type='text' className='col-8 col-sm-4' value={modeName} name='modeName' onChange={e => setmodeName(e.target.value)} onKeyDown={(e) => handleKeyDown(e, shortNameRef)} />
          </div>

          <div className=' col-sm-12 inputs' style={{ marginTop: "10px" }}>
            <label className='col-6 col-sm-2'><b> Short Name</b></label>
            <input ref={shortNameRef} type='text' className='col-8 col-sm-4' value={shortName} name='shortName' onChange={e => setshortName(e.target.value)} onKeyDown={(e) => handleKeyDown(e, saveButtonref)} />
          </div>
        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link ref={saveButtonref} className="btn2 col-2 col-sm-1" onClick={HandleSubmit} to=""> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link> : <>
            <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="../shipmentmodelist"> <i className="fa fa-edit"> </i>   Update </Link>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} > <i className="fa fa-trash"> </i>   Delete</button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i>   Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/shipmentmodelist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
    </>
  )
}

export default Shipmentmode
