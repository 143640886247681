import React, { useState, useEffect, useRef } from 'react'
import '../inward/inwarddbstatus.css';
import InwardContainerHeader from './InwardContainerHeader';
import InwardLogService from '../../services/inwardlog.service';
import { Link} from 'react-router-dom';
// import Sign from '../../components/Sign';
import SignatureCanvas from 'react-signature-canvas';
import InwardDeliverySheet from '../../services/inwarddeliverysheet.service';
import moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { Modal, Button } from 'react-bootstrap';
import MasterDataService from "../../services/masterData.service";

const Inwarddbstatus = () => {  
  const [rowData, setRowData] = useState([]);
  const [drsId, setdrsId] = useState(0);
  const [drsDetails, setDrsDetails] = useState([]); 
  const [redirectempId, setredirectempId] = useState(0)
  const [redirectempName, setredirectempName] = useState("")
  const [employeeList, setemployeeList] = useState([]);
  const [emptab, setemptab] = useState(false); 

  const [selectedRow, setSelectedRow] = useState<number>(0); 
  const tableRef = useRef<any>(null);
  const awbtableRef = useRef<any>(null);
  const [show, setShow] = useState(false);
  const [showSignbox, setShowSignbox] = useState(false)
  const [showUploadbox, setShowUploadbox] = useState(false)
  const [inwarddetailStatus, setinwarddetailStatus] = useState(0);

  const [receivedBy, setreceivedBy] = useState('');
  const [remark, setremark] = useState('');
  const [inwardDate, setinwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [inwardTime, setinwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm')); 
  const [showSign, setShowSign] = useState(false); 
  const [searchQuery, setSearchQuery] = useState('');
  const [statusReasons, setstatusReasons] = useState([]);
  const [inwarddetailList, setinwarddetailList] = useState([]);
  const [ReasonId, setReasonId] = useState(0);
  const [ReasonLabel, setReasonLabel] = useState('');
  const [savedSignature, setSavedSignature] = useState(null);
  const fileInputRef = useRef(null); // Reference to the file input element
  const [uploadedImageBase64, setUploadedImageBase64] = useState(null);
 
  const getDrsList = () => {
    InwardDeliverySheet.getPendingDrs().then(
      (data) => {
        if (data !== null) {
          const filteredData = data.filter((item: any) => item.pendingcount !== 0);
          setRowData(filteredData);
        }
      }
    ).catch((error) => {
      console.log(error);
      console.log("server responding");

    })
  }


  useEffect(() => {
    getDrsList()
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleRowClick = (drsIdRow: number) => { 
    setdrsId(drsIdRow)
    InwardDeliverySheet.getDrsDetailsbyId(drsIdRow).then(
      async (data) => { 
        if (data) {
          // console.log(data, " data.length", data.length);
          if(data.length > 0){
            setDrsDetails(data);
          }else {
            await InwardDeliverySheet.closedDrs(drsIdRow) ;
            alert("This DRS is alredy Closed")
          }
        }

      }
    ).catch((error) => {
      console.log(error);
      console.log("server responding");
    })
  };
  const handleOptionChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setinwarddetailStatus(Number(event.target.value));
    if (event.target.value === '8') {
      setShowSign(true)
    } else {
      setShowSign(false)
    }
    if (event.target.value === '9') { setReasonId(0) }
    if (event.target.value === '13') {
      setemptab(true)
    } else {
      setemptab(false)
    }
  };
  const signatureCanvasRef = useRef<any>(null);
  const handleShow = () => {
    setShow(true);
    setShowSignbox(true);
    setShowUploadbox(false)

  };
  const handleUpload = () =>{
    setShow(true);
    setShowSignbox(false); 
    setShowUploadbox(true)
  }
  const handleClose = () => setShow(false);

  const handleSave = () => {
    const dataUrl = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
    setSavedSignature(dataUrl);
    //  console.log(dataUrl); // Here you can handle the saved signature data
    handleClose();
  };

  const handleFileChange = (event :any) => {
    const file = event.target.files[0];
    if (file) {
      const reader :any = new FileReader();
      reader.onload = () => {
        setUploadedImageBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadSave = () =>{ 
    if (uploadedImageBase64) {
      setSavedSignature(uploadedImageBase64);
    } 
    // if (uploadedImageBase64) {
    //   const canvas = signatureCanvasRef.current;
    //   const ctx = canvas.getContext('2d');
    //   const img = new Image();
    //   img.onload = () => {
    //     ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before drawing the new image
    //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    //   };
    //   img.src = uploadedImageBase64;
    // }
      setShow(false);
  }

  useEffect(() => {
    getAllEmployee()
  }, [inwarddetailStatus])

  const getAllEmployee = async () => {
    await MasterDataService.getEmployeeList().then(
      (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.employeeName
          }))
          setemployeeList(options);
          // setallSelections(options)
        }
      }
    );
  }

  const HandleSubmit = () => {
    if ((inwarddetailStatus !== null) && (inwarddetailList.length !== 0)) {
      let deliverySignbase64 = "";
      if(savedSignature !== null){
        deliverySignbase64 = savedSignature;
      }
      // if (signatureCanvasRef.current) {
      //   deliverySignbase64 = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
      //   // setdeliverySign(signatureImage);
      // }
      // console.log("deliverySignbase64", deliverySignbase64);

      const inwarddetailid = inwarddetailList.map((inwdId: any) => {
        return inwdId.inwardDetailsId
      })
      // inwarddetailid.push(inwardId)
      InwardLogService.postupdateinwarddetailstatus(inwarddetailid, inwarddetailStatus, receivedBy, remark, inwardDate, inwardTime, deliverySignbase64, ReasonId, ReasonLabel, drsId, redirectempId, redirectempName).then(
        (data) => {
          if (data) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "Inward Status Updated Successfully",
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                title: 'swal-fire-alert', // Add your custom CSS class here
              },
            }).then(function () {
              window.location.reload();
              // navigate('../inworddetailslist');
            });
          }
          // console.log(data.statuscode)
          // if (data.statuscode === 200) {
          //     setinwarddetailStatus(data.message[0].inwarddetailStatus);
          // }
        }
      ).catch((error) => {
        console.log(error);
        console.log("server responding");
      })
    }
  }

  const clearSignature = () => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.clear();
    }
  }

  useEffect(() => {
    getRtoReasons()
  }, [inwarddetailStatus])
  const getRtoReasons = () => {
    let inwarddetailStatusresponce = 0;
    if (inwarddetailStatus === 9) {
      inwarddetailStatusresponce = 9
    }
    InwardDeliverySheet.getRtoReasons(inwarddetailStatusresponce, 1).then(
      (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.returnReason
          }))
          setstatusReasons(options)
        }
      }
    ).catch((error) => {
      console.log(error);
      console.log("server responding");
    })
  }

  interface selectRowType {
    mode: any;
    clickToSelect: boolean;
    style: any;
    onSelect: any;
    onSelectAll: any
  }

  const selectRow: selectRowType = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row: never, isSelect: any, rowIndex: any, e: any) => {
      if (isSelect) {
        // const rowsValues = {inwardDetailsId : row['id'], tCode : Date.now().toString(), mailRoomId : mailRoomId, branch : branch, createdBy : createdBy}
        // setselectedRecord([...selectedRecord, rowsValues]) ;
        setinwarddetailList([...inwarddetailList, row])
      } else {
        const secondIndex = inwarddetailList.findIndex((Selectrow: any) => Selectrow['inwardDetailsId'] === row['inwardDetailsId']);
        inwarddetailList.splice(secondIndex, 1);
        // console.log("inwarddetailList", inwarddetailList)
      }
    },
    onSelectAll: async (isSelect: any, rows: any, e: any) => {
      if (isSelect) {
        var IdArray: [] = [];
        rows.map(async (rowDatas: never) => {
          IdArray.push(rowDatas);
        })
        await setinwarddetailList(IdArray)
      } else {
        await setinwarddetailList([])
      }
    },
    style: (row: any, rowIndex: any) => {
      const backgroundColor = rowIndex >= 0 ? '#00BFFF' : '#00FFFF';
      return { backgroundColor };
    }
  }
 
  const columns: ColumnDescription<selectRowType>[] = [
    {
      dataField: 'inwardDetailsId', formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
        // return cell;
      }, text: 'Sr No', align: 'center'
    },
    { dataField: 'AWB', text: 'AWB No' },
    {
      dataField: 'inwardDate', text: 'Inward Date', headerAlign: 'left',// formatter : dateformater

    },
    // { dataField: 'inwardType', text: 'Type', headerAlign: 'left', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }, classes:"showinline" },
    { dataField: 'inwardQuantity', text: 'Pcs.', align: 'center' },
    { dataField: 'consignerName', text: 'Consignor', align: 'center' },
    { dataField: 'employeeName', text: 'Employee', align: 'center' },
    { dataField: 'statusname', text: 'Status', align: 'center' },
    { dataField: 'deliveryremark', text: 'Remark', align: 'center' },
    // { dataField: 'inwardType', text: 'Inward Type',  align: 'center'},


  ];
  const filteredData = Array.isArray(drsDetails)
    ? drsDetails.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];

  interface selectRowTypeListAll {
    mode: any;
    clickToSelect: boolean;
    style: any;
    onSelect: any;
  }

  const selectRowAll: selectRowTypeListAll = {
    mode: "radio",
    clickToSelect: true,
    onSelect: (row: never, isSelect: any, rowIndex: any, e: any) => {
      if (isSelect) {
        handleRowClick(row['id'])
        setSelectedRow(rowIndex);
      }
    },
    style: (row: any, rowIndex: any) => {
      const backgroundColor = rowIndex >= 0 ? '#00BFFF' : '#00FFFF';
      return { backgroundColor };
    }
  }
  const columnsAll: ColumnDescription<selectRowType>[] = [
    {
      dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
        // return cell;
      }, text: 'Sr No', align: 'center'
    },
    { dataField: 'drsDate', text: 'Drs Date' },
    { dataField: 'drsNumber', text: 'Drs Number' },
    { dataField: 'deliveryBoyName', text: 'Delivery Boy' }
  ]


  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-red text-white'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );


  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
  const filteredDataAll = Array.isArray(rowData)
    ? rowData.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
    : [];


  const handleKeyDown = async (e: any) => {
    if (!tableRef.current) return;
    // e.preventDefault(); 
    // Get the current selected row index 
    let currentIndex = await selectedRow ?? 0;
    // let currentIndex = tableRef.current.props.selectedRowKeys[0];
    // Handle arrow key events
    switch (e.key) {
      case 'ArrowDown':
        if (currentIndex < tableRef.current.props.data.length - 1) {
          currentIndex++;
        }
        break;
      case 'ArrowUp':
        if (currentIndex > 0) {
          currentIndex--;
        }
        break;
      default:
        return;
    }
    console.log(currentIndex)
    // Update the selected row index
    setSelectedRow(currentIndex);
  };
 
  return (
    <>
      <InwardContainerHeader />
      <div className='mb-10'>
        <div style={{ margin: "0px" }}>
          <div className='' style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
            <div className="container col-11" style={{ padding: '0px' }}>
              <div className="themeColor"  > Update Inward Status  </div>
              <div className='col-12 inline' style={{ padding: '0px' }}>
                <div className='col-4' style={{ padding: '0px' }}>
                  <div className='col-12' style={{ padding: '0px' }}>
                    <div>
                      <div className="table-responsive">
                        <div className='table-responsive tableContainer'>
                          <BootstrapTable
                            keyField='id'
                            striped
                            hover
                            condensed
                            // pagination={pagination}
                            data={filteredDataAll}
                            filter={filterFactory()}
                            filterPosition="top"
                            columns={columnsAll}
                            selectRow={selectRowAll}
                            ref={tableRef}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-8'>
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField='inwardDetailsId'
                      striped
                      hover
                      condensed
                      data={filteredData}
                      filter={filterFactory()}
                      filterPosition="top"
                      columns={columns}
                      selectRow={selectRow}
                      ref={awbtableRef}
                    />
                  </div>
                </div>

              </div>

              <div className='card container'>
                <div className='bottombtns' >
                  <div className='col-12 inline'>
                    <div className='col-12 inline'>
                      <label className='Radiobtn col-2 inline'><input type='radio' className='radio-btn'
                        value="8"
                        name="deliveryStatus"
                        checked={inwarddetailStatus === 8}
                        onChange={handleOptionChange1}

                      /> Delivered</label>

                      <label className='Radiobtn col-2 inline'><input type='radio' className='radio-btn'
                        value="9"
                        name="deliveryStatus"
                        checked={inwarddetailStatus === 9}
                        onChange={handleOptionChange1}

                      /> Undelivered</label>


                      <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                        value="13"
                        name="deliveryStatus"
                        checked={inwarddetailStatus === 13}
                        onChange={handleOptionChange1}
                      /> Redirected</label>

                      <label className='Radiobtn col-2'>
                        <input type='radio' className='radio-btn'
                          value="14"
                          name="deliveryStatus"
                          checked={inwarddetailStatus === 14}
                          onChange={handleOptionChange1}
                        /> Unclaimed</label>
                      <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                        value="16"
                        name="deliveryStatus"
                        checked={inwarddetailStatus === 16}
                        onChange={handleOptionChange1}
                      /> OnHold</label>
                    </div > 
                  </div>
                </div>
                <div className='col-12 inline' style={{ minHeight: 200 }}>
                  <div className='col-8'>

                    {(statusReasons.length !== 0) && (
                      <div className='col-12 inline' style={{ marginTop: '10px' }}>
                        <label className='col-4'>Reason </label>
                        <Select
                          name="ReasonId"
                          onChange={(e: any) => { setReasonId(e.value); setReasonLabel(e.label) }}
                          options={statusReasons}
                          value={{ value: ReasonId, label: ReasonLabel }}
                          className='col-8'
                        />
                        {/* <input className='col-8'type="text"   value={remark} name='remark'onChange={e => setremark(e.target.value)} /> */}
                      </div>)}
                    {emptab && (
                      <div className='col-12 inline' style={{ marginTop: '10px' }}>
                        <label className='col-4'>Redirect To </label>
                        <Select
                          name="employee"
                          onChange={(e: any) => { setredirectempId(e.value); setredirectempName(e.label) }}
                          options={employeeList}
                          value={{ value: redirectempId, label: redirectempName }}
                          className='col-8'
                        />
                      </div>
                    )}

                    {showSign && (<div className='col-12 inline' style={{ marginTop: '10px' }}>
                      <label className='col-4'>Received By</label>
                      <input className='col-8' type="text"
                        value={receivedBy} name='receivedBy' onChange={e => setreceivedBy(e.target.value)} />
                    </div>)}
                    <div className='col-12 inline' style={{ marginTop: '10px' }}>
                      <label className='col-4'>Status Date & Time</label>
                      <input className='col-4' type="date"
                        value={inwardDate} name='inwardDate' onChange={e => setinwardDate(e.target.value)} />
                      <input className='col-4' type="time" style={{}}
                        value={inwardTime} name='inwardTime' onChange={e => setinwardTime(e.target.value)} />
                    </div>

                    <div className='col-12 inline' style={{ marginTop: '10px' }}>
                      <label className='col-4'>Remark</label>
                      <input className='col-8' type="text" value={remark} name='remark' onChange={e => setremark(e.target.value)} />
                    </div>
                  </div>
                  <div className='col-4'>
                    {showSign && (
                      <div >
                        <h5>Sign Box</h5>
                        <div>
                        {savedSignature && (
                          <div className="savedSignatureBox">
                            <h2>Signature</h2>
                            <img src={savedSignature} alt="Saved Signature" width="250px" height="150px" />
                          </div>
                        )}
                          {/* <SignatureCanvas
                            ref={signatureCanvasRef}
                            penColor="black"
                            canvasProps={{ width: 300, height: 100, className: 'sigCanvas border' }}
                          // canvasProps={{ width, height }}
                          /> */}
                          <div className='row'>
                            <button className='btn2 col-3'onClick={handleShow}><i className='fa fa-pencil'></i> Sign </button>
                            <button className='btn2 col-3' onClick={handleUpload}><i className='fa fa-upload'></i> Sign </button>
                            {/* <button className='btn2 col-3' onClick={clearSignature}>Clear</button> */}
                          </div>
                        </div>
                      </div>
                    )}

                  </div>
                </div>


              </div>
              <div className='btn d-flex justify-content-center'>
                <button className="btn2 col-2 col-sm-1" type="button" onClick={HandleSubmit}><i className="fa fa-save"></i> Save</button>
                <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
                <Link className="btn2 col-2 col-sm-1" to="/inwardloglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
        {(showSignbox && <><Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <SignatureCanvas
            ref={signatureCanvasRef}
            penColor="black"
            canvasProps={{ className: 'sigCanvas border canvasSize' }}
            minWidth={2} // minimum pen width
            maxWidth={4} // maximum pen width
            throttle={16} // control the smoothness of the stroke
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={clearSignature}>
            Clear
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer></>)}
        {(showUploadbox && <><Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">Upload Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <input 
            type='file' 
            ref={fileInputRef} 
            name="fileUpload" 
            placeholder='Upload Sign Image' 
            onChange={handleFileChange} 
          />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" 
              // onClick={() => fileInputRef.current && (fileInputRef.current = '')}
              >
                Clear
              </Button>
              <Button variant="primary" onClick={handleUploadSave}>
                Save
              </Button>
            </Modal.Footer>
            </>)}
      </Modal>
      </div>
    
    </>

  )
}

export default Inwarddbstatus