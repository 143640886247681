import DashboardHeader from '../DashboardHeader';
import '../dashboard.css';
import Charts from './charts';
import OutwardStatusBarChart from './outwardStatusBarChart';
import DatewiseTable from './outDatewiseTable'; 
import { useEffect, useState } from 'react';
import moment from 'moment';
import DashboardDataService from '../../../services/dashboard.service';
import Logvsentry from './logvsentry'; 
import MonthlyDatewiseVendorCount from './monthlyDatewiseVendorCount'


const OutwardDashboard = () => {

  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'));
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));

  const [lastDate, setLastDate] = useState(31);
  const [todaysDate, settodaysDate] = useState(moment().format('DD/MM/YYYY'));
  const [dateWiseData, setdateWiseData] = useState([]);
  const [dataforBarChart, setdataforBarChart] = useState([]);
  const [inwardShipmentofMonth, setInwardShipmentofMonth] = useState([]);
  const [months, setMonths] = useState<any>([]);
  const [todaysOutwardLog, setTodaysOutwardLogcount] = useState(0)
const [todaysOutwardDetail, setTodaysOutwardDetail] = useState(0)
const [todaysOutwardDetailPending, setTodaysOutwardDetailPending] = useState(0)
const [todaysOutwardDetailDeliverded, setTodaysOutwardDetailDelivered] = useState(0)
const [todaysOutwardDetailRedirect, setTodaysOutwardDetailRedirect] = useState(0)
const [outwardShipmentMonthlyCount, setoutwardShipmentMonthlyCount] = useState([])
  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      const [month, year] = selectedOption.value.split('/');
      setSelectedMonth(month);
      setSelectedYear(year);
    }
  };
  useEffect( () => {
    const monthsData: any = [];
    for (let i = 0; i < 12; i++) {
      const month = moment().clone().subtract(i, 'months');
      const monthLabel = month.format('MMMM');
      const yearLabel = month.format('YYYY');
      const value = month.format('MM/YYYY');
      monthsData.push({ label: `${monthLabel} ${yearLabel}`, value });    
    }
    setMonths(monthsData);
    getDateWiseInwardShipment();  
    getinwardStatusForBarChart();
    inwardshipmentofmonth();
    outwardshipmentmonthlycount();
  }, []);
  
  useEffect(()=>{       
    getinwardStatusForBarChart();
    getDateWiseInwardShipment();
    inwardshipmentofmonth();
    const dts = selectedYear + '-'+selectedMonth+'-01'
    const ldate = moment(dts).endOf('month').format("DD");
      setLastDate(parseInt(ldate))     
  },[selectedMonth])


  


  // ------  DatwWise Shipment -------
  const getDateWiseInwardShipment = async () => {
   await DashboardDataService.getDateWiseOutwardShipment(selectedMonth, selectedYear).then(
      async (data) => {
      await setdateWiseData(data)
      }).catch((error) => {
      console.log(error);
      // console.log("server Responding");
    })
  }

  const getinwardStatusForBarChart = async () => {
    await DashboardDataService.getoutwardstatusofmonth(selectedMonth, selectedYear).then(
      async (data) => {
        if(data !==""){
          const options = data.map((d: any) => ({
            "value": d.count,
            "name": d.description
        }))
        await setdataforBarChart(options)
        }else {
          const data1 :any = [
            { name: "Page A", value: 4000 },
            { name: "Page B",  value: 3000 },
            { name: "Page C", value: 2000 },
            { name: "Page D",  value: 2780},
            { name: "Page E",   value: 1890 }            
          ];
          await setdataforBarChart(data1)
        }
      }

    ).catch((error) => {
      console.log(error);
      console.log("server Responding");
    }) 
  }
  const inwardshipmentofmonth = async () => {
    await DashboardDataService.outwardshipmentofmonth(selectedMonth, selectedYear).then(
      async (data) => {       
          await setInwardShipmentofMonth(data) 
          await setTodaysOutwardDetail(data[0].todaysOutwardEntry);
          await setTodaysOutwardDetailDelivered(data[0].todaysOutwardEntryDelivered);
          await setTodaysOutwardDetailPending(data[0].todaysOutwardEntryPending);
          await setTodaysOutwardDetailRedirect(data[0].todaysOutwardEntryRedirect);
          await setTodaysOutwardLogcount(data[0].todaysOutwardLog);  
      }

    ).catch((error) => {
      console.log(error);
    }) 
  }

  const outwardshipmentmonthlycount = async () => {
    await DashboardDataService.outwardshipmentmonthlycount(selectedMonth, selectedYear).then(
      async (data) => {     
        setoutwardShipmentMonthlyCount(data)
      }
    ).catch((error) => {
      console.log(error);
    }) 
  } 
  const data = [{ name: 'Log vs Outward', value: todaysOutwardLog, color: '#db1c29' }]; 
  const data1 = [{ name: 'At Mailroom Desk', value: todaysOutwardDetail, color: '#f9f324' }]; 
  const data2 = [{ name: 'Deliverd', value: todaysOutwardDetail, color: '#4fce14' }]; 
  const data3 = [{ name: 'Redirect', value: todaysOutwardDetail, color: '#0f6af5' }]; 
  const cx = 140; // X-coordinate of the pie chart's center
const cy = 160; // Y-coordinate of the pie chart's center
const iR = 60; // Inner radius of the pie chart
const oR = 100; // Outer radius of the pie chart
  return (
    <div className='overflow'>
      <DashboardHeader selectedMonth={selectedMonth} selectedYear ={selectedYear} months={months} handleSelectChange={handleSelectChange}/>
       <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>          
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
      <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}> Outbound Dashboard </p></div>
      <div className='width dash-main'>
      <DatewiseTable selectedMonth={selectedMonth} selectedYear ={selectedYear} dateWiseData={dateWiseData} lastDate = {lastDate}/>
     </div>
     <div>
     <MonthlyDatewiseVendorCount selectedMonth={selectedMonth} selectedYear ={selectedYear} dateWiseData={outwardShipmentMonthlyCount} lastDate = {lastDate} />
      
     </div>
      
      <div className='col-12 row'>
        <div className='col-3'>
        <Logvsentry data={data} cx={cx} cy={cy} iR={iR} oR={oR} value={todaysOutwardDetail}/>
        </div> 
        <div className='col-3'>
        <Logvsentry data={data1} cx={cx} cy={cy} iR={iR} oR={oR} value={todaysOutwardDetailPending}/>
        </div> 
        <div className='col-3'>
        <Logvsentry data={data2} cx={cx} cy={cy} iR={iR} oR={oR} value={todaysOutwardDetailDeliverded}/>
        </div> 
        <div className='col-3'>
        <Logvsentry data={data3} cx={cx} cy={cy} iR={iR} oR={oR} value={todaysOutwardDetailRedirect}/>
        </div> 
      </div>
      
      <div className='width inline'>
        <div className='col-9 bartable'> 
          <OutwardStatusBarChart  dataforBarChart ={dataforBarChart} todaysDate={todaysDate} />
        </div>
        <div className='col-3 paichart'>
          <Charts selectedMonth={selectedMonth} selectedYear ={selectedYear} inwardShipmentofMonth={inwardShipmentofMonth} />
        </div>
      </div>
 
    </div>
    </div>
    </div>
    </div>
  )
}

export default OutwardDashboard