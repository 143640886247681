import { PieChart, Pie, Cell,  Text } from 'recharts';

const RADIAN = Math.PI / 180;

// Function to create the needle
const needle = (value:any, data:any, cx:any, cy:any, iR:any, oR:any, color:any) => {
  let total = 0;
  data.forEach((v:any) => {
    total += v.value;
  });

  const ang = 180 * (1 - value / total); // Angle for the needle
  const length = (iR + 2 * oR) / 3; // Needle length
  const sin = Math.sin(-RADIAN * ang); // Sine of the angle
  const cos = Math.cos(-RADIAN * ang); // Cosine of the angle

  const r = 5; // Radius of the needle's base
  const x0 = cx + 5; // X-coordinate for the needle's base
  const y0 = cy + 5; // Y-coordinate for the needle's base
  const xba = x0 + r * sin; // X-coordinate for the back point A
  const yba = y0 - r * cos; // Y-coordinate for the back point A
  const xbb = x0 - r * sin; // X-coordinate for the back point B
  const ybb = y0 + r * cos; // Y-coordinate for the back point B
  const xp = x0 + length * cos; // X-coordinate for the pointer's tip
  const yp = y0 + length * sin; // Y-coordinate for the pointer's tip

  return (
    <>
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />
      <path d={`M${xba} ${yba} L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="none" fill={color} />
    </>
  );
};

// Converted to functional component
interface ExampleProps {
    value: number; // The value to point at
    data: { name: string; value: number; color: string }[]; // Data for the pie chart
    cx: number; // Center x-coordinate
    cy: number; // Center y-coordinate
    iR: number; // Inner radius
    oR: number; // Outer radius
  }
  
  const Logvsentry: React.FC<ExampleProps> = ({ value, data, cx, cy, iR, oR }) => {
  return ( 
     <div style={{ width: '100%', height: '300px', textAlign: 'center' }}> {/* Parent container with fixed height */}
      <PieChart width={300} height={170}> {/* PieChart with height in percentage */}
      <Pie
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={data} // Data for the pie chart
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill="#8884d8" 
        stroke="none"
      >
        {data.map((entry:any, index:any) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}  
      
      </Pie>

      {/* Needle pointing to the specified value */}
      {needle(value, data, cx, cy, iR, oR, '#808080')}
    </PieChart>
    <div style={{ textAlign: 'center', marginTop: '10px' }}> {/* Label with additional spacing */}
        <strong>{data[0].name}</strong> {/* Displaying the name of the first data segment */}
      </div>
   </div>
  );
};

export default Logvsentry;
