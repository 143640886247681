function monthlyDatewiseVendorCount(props:any) {
  
  const totalCounts: number[] = Array(props.lastDate).fill(0); 
  props.dateWiseData.forEach((data: any) => { 
    if (Array.isArray(data.dateWiseData)) {
      data.dateWiseData.forEach((dayData: any, index: number) => {
        const count = parseInt(dayData.dates, 10); // Convert to number with base 10
        if (!isNaN(count)) { // Ensure it's a valid number
          totalCounts[index] += count; // Accumulate the count
        }
      });
    }
  });
  return (        
      <table className="table1 width ">
        <thead className="thead" style={{border : '1px solid #ccc'}}>
          <tr  style={{ textAlign: "center", border: "1px solid #ccc" }}>
            <th scope="col" style={{border : '1px solid #ccc'}}>Service Provider</th>             
            { (props.lastDate)  ? Array(props.lastDate).fill(0).map((e, i) => ( <td key={i} style={{border : '1px solid #ccc'}}> {i + 1}</td>)) : '' }       

          </tr>
        </thead>


        <tbody className="width scroll-table " style={{ border: "1px solid grey" }}>
{ Array.isArray(props.dateWiseData)
  ? props.dateWiseData.map( (d:any, index:any) =>{
  return <tr key={index + 500} style={{textAlign: "center", border : '1px solid #ccc'}}>
<td style={{border : '1px solid #ccc', textAlign :"left"}}>
  {d.serviceProvider}
</td>
{
  d.dates.map((dates:any, ind:any) => {
    
   return <td key={ind + 1000} style={{border : '1px solid #ccc'}}>{(dates !== 0)? dates : '-'} </td>
  })
}
  </tr>
} ) : []}   
        </tbody>
        <tfoot>
          <tr style={{ border: "1px solid grey" }}>
            <td>Total</td>
            {Array.from({ length: props.lastDate }, (_, i) => {
              const total = props.dateWiseData.reduce((acc: number, curr: any) => acc + parseInt(curr.dates[i] || 0), 0);
              return <td key={i} style={{textAlign: "center",  border: '1px solid #ccc' }}>{total}</td>;
            })}
          </tr>
        </tfoot>

      </table>
  )
}

export default monthlyDatewiseVendorCount